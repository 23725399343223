/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnWrapper, Title, Text, YouTube, Image, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu mt--50 pb--12 pt--12" menu={true} name={"menu"} style={{"left":0,"z-index":"3","position":"absolute"}}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--style3 fs--24 w--400 btn-box--invert" href={"/jazz"} style={{"style":5}} target={""} content={"&nbsp;<span style=\"\" wfd-id=\"5192\">Jazz &nbsp;</span>"}>
              </Button>

              <Button className="btn-box btn-box--style2 fs--24 w--400 btn-box--invert" href={"/pop-rock"} style={{"style":5,"backgroundColor":"rgba(114,78,51,1)"}} target={""} content={"<font color=\"#af7d23\">Pop-Rock</font>"}>
              </Button>

              <Button className="btn-box btn-box--style2 fs--24 w--400 btn-box--invert" href={"/film-like"} style={{"style":5,"backgroundColor":"rgba(120,80,41,1)"}} target={""} content={"<span style=\"color: rgb(185, 129, 34);\">Film- like</span>"}>
              </Button>

              <Button className="btn-box btn-box--style3 fs--24 w--400 btn-box--invert" href={"/noborders"} style={{"style":5}} target={""} content={"No borders"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="css-1c39s9k --border2 --full --parallax pb--30 pt--30" name={"uvod"} border={"2"} parallax={true} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="--center el--1 pb--30 flex--stretch" style={{"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper style={{"marginTop":68,"paddingTop":103}}>
              
              <Title className="title-box title-box--left fs--72 title-box--invert" style={{"marginTop":54}} content={"Otto Orany"}>
              </Title>

              <Text className="text-box text-box--left fs--16 text-box--invert ls--12" style={{"marginTop":0,"marginBottom":64,"paddingBottom":20.140625}} content={"<br><br>Musical adventurer<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"y4wlqo4w9u"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Compassion"}>
              </Title>

              <Text className="text-box fs--18" content={"feat. Ivan Skřítek"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"QC3zzknmoVY"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2 pb--60 pt--60 flex--center" style={{"border":"1em solid var(--color-main-3)","maxWidth":"","marginTop":0,"paddingTop":null,"paddingLeft":null,"paddingBottom":null}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"CONTACT"}>
              </Title>

              <Text className="text-box" content={"Exploration in the jazz, rock, pop, electronic, funk, classical music and avantgarde<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"otto.orany@email.cz"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"<br>composer &amp; pianist<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"vyhody"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Jazz<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>Jazz mnoha podob kombinovaných mezi sebou i s jinými styly<br>Jazz combined with styles of itself or different styles<br>"}>
              </Text>

              <Button className="btn-box" href={"/jazz"} content={"Jazz<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":308}}>
              
              <Subtitle className="subtitle-box" content={"Pop-Rock<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>Pop, rock, hard rock, funk a dance&nbsp;<br>Progressive pop containing rock, hard rock, funk and dance<br><br>"}>
              </Text>

              <Button className="btn-box" href={"/pop-rock"} content={"Pop-Rock<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Film-like<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Hudba ovlivněná filmovou hudbou, především její výpravností<br>Music influenced by film music furthermore by its telling stories<br><br>"}>
              </Text>

              <Button className="btn-box" href={"/film-like"} content={"Film-like<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"No borders<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Avantgarda, jiné styly a záznamy živých vystoupení, které se jinam nevešla<br>Avantguarde, other styles and also live concerts<br><br><br>"}>
              </Text>

              <Button className="btn-box" href={"/noborders"} content={"No borders<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c39s9k --border2 pb--50 pt--50" name={"banner"} border={"2"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72 title-box--invert" content={"OTTO ORANY<br>"}>
              </Title>

              <Text className="text-box fs--16 text-box--invert ls--12 mt--08" content={"<font color=\"#002625\">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; MUSICAL ADVENTURER</font><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--20" name={"paticka"} style={{"paddingTop":null}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"Vytvořeno přes <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}